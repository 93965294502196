<template>
    <div id="footerService">
        <div class="footer_top">
            <div class="logo_left">
                <img class="leftLogoImg" src="../assets/img/serviceSystem/serviceLogo.png" alt="" @click="toService" />
                <div class="warkTime">
                    <img class="timeImg" src="../assets/img/phone2.png" alt="" />
                    <span>400-698-6665</span>
                </div>
                <div class="QRCode">
                    <div class="WeChat">
                        <img class="WeChatImg" src="../assets/img/code.png" alt="" />
                        <p>官方微信</p>
                    </div>
                    <div class="WeChatShopping">
                        <img class="WeChatShoppingImg" src="../assets/img/code.png" alt="" />
                        <p>微信商城</p>
                    </div>
                </div>
            </div>
            <div class="logo_right">
                <p>快速链接</p>
                <div class="link">
                    <ul class="tol ulLi">
                        <li class="pointer hover" @click="toLinkNav(item)" v-for="item in service" :key="item.id">
                            {{ item.name }}
                        </li>
                    </ul>
                    <ul class="tol ulLi">
                        <li class="pointer hover" @click="toLinkNav(item)" v-for="item in afterSaleNav" :key="item.id">
                            {{ item.name }}
                        </li>
                    </ul>
                    <ul class="ulLi">
                        <li class="pointer hover" @click="toLinkNav(item)" v-for="item in gej" :key="item.id">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div style="height: 1px; width: 100%; border: 1px solid rgb(227, 226, 226)"></div>
        <div class="footer-bottom pointer" @click="recordBtn">
            Copyright 2022 - 2026 Zhushou Network Co., Ltd. 筑手网络有限公司 版权所有
            粤ICP备20000797号-2.
        </div>
    </div>
</template>

<script>
export default {
    name: 'GejMallPcFooterservice',

    data() {
        return {
            service: [
                {
                    id: 0,
                    name: '果尔佳服务',
                    url: '/serviceSystem/home',
                },
                {
                    id: 1,
                    name: '装修服务',
                    url: '/serviceSystem/construct',
                },
                {
                    id: 2,
                    name: '技能培训',
                    url: '/serviceSystem/skill',
                },
                {
                    id: 3,
                    name: '加盟申请',
                    url: '/serviceSystem/join',
                },
            ],
            afterSaleNav: [
                {
                    id: 4,
                    name: '售后服务',
                    url: '/serviceSystem/afterSales',
                },
                {
                    id: 6,
                    name: '服务体系',
                    url: '/serviceSystem/service',
                },
                {
                    id: 7,
                    name: '在线客服',
                },
            ],
            gej: [
                {
                    id: 8,
                    name: '果尔佳集团',
                    url: 'http://www.golkia.com',
                },
                {
                    id: 9,
                    name: '筑手建筑防护超市',
                    url: '/homes/index',
                },

                {
                    id: 10,
                    name: '果尔佳职业技术培训学校',
                    url: 'https://www.golkia-school.com',
                },
                {
                    id: 11,
                    name: '果尔佳建筑产业',
                    url: 'http://www.golkia.net',
                },
            ],
        }
    },

    mounted() { },

    methods: {
        // 进入服务体系首页
        toService() {
            if (this.$route.path != '/serviceSystem/home') {
                this.$router.push({ path: '/serviceSystem/home' })
            }
        },
        // 底部连接跳转
        toLinkNav(item) {
            if (item.url) {
                if (item.id == 8 || item.id == 10 || item.id == 11) {
                    window.open(item.url, '_blank')
                } else if (item.id == 9) {
                    if (this.$route != '/homes/index') {
                        this.$router.push({ path: item.url })
                    }
                } else {
                    let routerData = this.$router.resolve({
                        path: item.url,
                        query: {
                            id: item.id,
                        },
                    })
                    window.open(routerData.href, '_blank')
                }
            }
        },
        // ipc跳转
        recordBtn() {
            window.open(' https://beian.miit.gov.cn/', '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
#footerService {
    width: 100%;
    height: 446px;

    .footer_top {
        height: 382px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-right: 91px;

        .logo_right {
            p {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-top: 62px;
            }

            .link {
                display: flex;

                .tol {
                    margin-right: 191px;
                }

                .ulLi {
                    .hover {
                        margin-top: 39px;
                    }

                    .hover:hover {
                        color: #03825a;
                    }
                }
            }
        }

        .logo_left {
            .leftLogoImg {
                width: 300px;
                height: 58px;
                margin-top: 65px;
                margin-left: 25px;
                margin-bottom: 18px;
                cursor: pointer;
            }

            .warkTime {
                display: flex;
                margin-left: 25px;
                margin-bottom: 28px;

                .timeImg {
                    width: 34px;
                    height: 34px;
                    margin-right: 31px;
                }

                span {
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                }
            }

            .QRCode {
                display: flex;
                margin-left: 58px;

                .WeChat {
                    margin-right: 31px;

                    .WeChatImg {
                        width: 90px;
                        height: 90px;
                        margin-bottom: 17px;
                    }

                    P {
                        text-align: center;
                    }
                }

                .WeChatShopping {
                    .WeChatShoppingImg {
                        width: 90px;
                        height: 90px;
                        margin-bottom: 17px;
                    }

                    P {
                        text-align: center;
                    }
                }
            }
        }
    }

    .footer-bottom {
        width: 1200px;
        margin: 0 auto;
        line-height: 62px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #888888;
    }
}
</style>