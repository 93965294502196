<template>
    <div id="serviceSystem">
        <div class="head">
            <div class="logo">
                <div class="imgBox">
                    <img class="pointer" @click="toService" src="@/assets/img/serviceSystem/serviceLogo.png" alt="" />
                </div>
                <div id="service">
                    <div :class="$route.query.id == item.id ? 'nav gree' : 'nav'" v-for="item in $store.state.navService"
                        :key="item.id" @click="addBtn(item)">
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <router-view />
        </div>
        <footerService></footerService>
        <div class="shortcut">
            <transition>
                <div class="gree tran" v-show="hide" @click="btnHide">
                    <span>免费预约</span>
                </div>
            </transition>
            <div class="consultBox" v-for="item in fastList" :key="item.id" @mouseover="mouseOver(item.id)"
                @mouseleave="isShow = null" @click="btnShortcut(item)">
                <span>{{ item.name }}
                    <div v-if="isShow == 3 && item.id == 3" class="consultPhone display">
                        <img class="icon_img" src="@/assets/img/phone2.png" alt="" />
                        400-698-6665
                    </div>
                </span>
            </div>
            <div class="topText display column pointer" style="border-bottom: none" v-show="fixed" @click="toTop">
                <img src="@/assets/img/upImgs.png" alt="" />
                <p>顶部</p>
            </div>
        </div>
        <!-- 快速预约 -->
        <transition @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
            <div class="footerAppoint" v-show="isShow">
                <div class="appoint-main">
                    <div class="appoint-icon">
                        <img class="pointer" @click="btnShow" src="../../assets/img/serviceSystem/off.png" alt="" />
                    </div>
                    <div class="appoint-body">
                        <p class="title">
                            <span class="title_text left">告别墙面斑驳，房屋焕新颜</span>果尔佳家装 · 建材为您<span
                                class="title_text right">一站式服务</span>
                        </p>
                        <el-form :model="form" label-position="right" :rules="rules" ref="form">
                            <el-form-item prop="userName">
                                <el-input v-model="form.userName" placeholder="姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="reserveType">
                                <!-- 1装修服务 2技能培训 3加盟申请 -->
                                <el-select v-model="form.reserveType" placeholder="服务类型">
                                    <el-option label="装饰服务" value="1"></el-option>
                                    <el-option label="技能培训" value="2"></el-option>
                                    <el-option label="加盟申请" value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="address" prop="province">
                                <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                                    @change="selectArea" placeholder="请选择城市" clearable></el-cascader>
                            </el-form-item>
                            <el-form-item prop="phone">
                                <el-input v-model="form.phone" maxlength="11" placeholder="手机号"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <div class="note">
                                    <input class="codeInput" type="text" v-model="form.code" placeholder="验证码" />
                                    <div v-if="isCode" class="item_error">
                                        请输入正确格式验证码
                                    </div>
                                    <div class="code">
                                        <a class="sendMsg pointer display" v-show="Verifications == 1"
                                            @click="getCodeBtn2">获取验证码</a>
                                        <a class="sendMsg pointer display" v-show="Verifications == 2">
                                            <span>{{ timers }}</span>s
                                        </a>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('form')">免费预约</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 预约消息提示 -->
        <el-dialog :visible.sync="dialogVisible" width="500px" center>
            <div class="title">
                <i class="el-icon-success"></i><span>恭喜您预约成功</span>
            </div>
            <div class="con">果尔佳客服人员将在30分钟内与您电话联系！</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">好的</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import areaJSON from '../../http/area.json'
import footerService from '../../components/footerService.vue'
export default {
    name: 'GejMallPcTopnavigationbar',
    components: {
        footerService,
    },
    data() {
        return {
            isShow: null,
            hide: false,
            fixed: false,
            fastList: [
                {
                    id: 2,
                    name: '在线客服',
                },
                {
                    id: 3,
                    name: '咨询热线',
                },
                {
                    id: 4,
                    name: '售后服务',
                    url: '/serviceSystem/afterSales',
                },
            ],
            Verifications: '1', // 定时器显示-隐藏
            timers: 60,
            options: areaJSON,
            isCode: false,
            isShow: true,
            form: {
                userName: '', //姓名
                reserveType: '', //服务类型
                phone: '', //手机号码
                province: '', // 省
                city: '', // 市
                district: '', // 区
                code: '', // 验证码
            },
            rules: {
                userName: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请填写11位手机号', trigger: 'blur' },
                    {
                        pattern: /^1[3|5|7|8|9]\d{9}$/,
                        message: '请输入正确的号码格式',
                        trigger: 'change',
                    },
                ],
                reserveType: [
                    { required: true, message: '请选择服务类型', trigger: 'change' },
                ],
                province: [
                    { required: true, message: '请选择所在地区', trigger: 'change' },
                ],
                code: [{ required: true, message: '请填写验证码', trigger: 'blur' }],
            },
            props: {
                children: 'children',
                label: 'name',
                value: 'name',
            },
            AreaArray: [],
            dialogVisible: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('scroll', this.fixedActiveBtn)
    },
    watch: {
        isShow: {
            handler(newValue, oldValue) {
                switch (newValue) {
                    case false:
                        this.hide = true
                        break
                    case true:
                        this.hide = false
                        break
                    default:
                        break
                }
            },
        },
    },

    methods: {
        addBtn(data) {
            if (data.id == 5) {
                const routes = this.$router.resolve({ path: data.url })
                window.open(routes.href, '_blank')
            } else {
                const routeData = this.$router.resolve({
                    path: data.url,
                    query: {
                        id: data.id,
                    },
                })
                window.open(routeData.href, '_blank')
            }
        },
        // 快捷按钮
        btnShortcut(data) {
            switch (data.id) {
                case 4:
                    if (data.url) {
                        const routerDate = this.$router.resolve({
                            path: data.url,
                            query: { id: data.id },
                        })
                        window.open(routerDate.href, '_blank')
                    }
                    break
                default:
                    break
            }
        },
        btnHide() {
            this.isShow = !this.isShow
        },
        // 确定预约
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.api.saveReserve(this.form).then((res) => {
                        if (res.code == 0) {
                            this.dialogVisible = true
                            // 清楚form表单
                            this.form = {
                                userName: '', //姓名
                                reserveType: '', //服务类型
                                phone: '', //手机号码
                                province: '', // 省
                                city: '', // 市
                                district: '', // 区
                                code: '', // 验证码
                            }
                            this.AreaArray = []
                        }
                    })
                } else {
                    return false
                }
            })
        },
        // 获取验证码
        getCodeBtn2() {
            let phone = this.form.phone
            if (!/^1[3|5|7|8|9]\d{9}$/.test(phone)) {
                this.newPhone = ''
                return (this.isPhone = true)
            } else {
                this.isPhone = false
            }
            this.Verifications = 2
            let auth_timers = setInterval(() => {
                //定时器设置每秒递减
                this.timers-- //递减时间
                if (this.timers <= 0) {
                    this.timers = 60
                    this.Verifications = 1 //60s时间结束还原v-show状态并清除定时器
                    clearInterval(auth_timers)
                }
            }, 1000)
            this.api.sendCode({ phone: phone }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('您的验证码已通过短信发送,请注意查收')
                } else {
                    this.Verifications = 1
                    this.timers = 60
                    this.$message.error(res.msg)
                }
            })
        },
        // 选择省市区
        selectArea(val) {
            this.form.province = val[0]
            this.form.city = val[1]
            this.form.district = val[2]
        },
        // 快速预约关闭
        btnShow() {
            this.isShow = false
        },
        // 进入服务体系首页
        toService() {
            if (this.$route.path != '/serviceSystem/home') {
                this.$router.push({ path: '/serviceSystem/home' })
            }
        },
        // 显示关闭动画效果
        beforeEnter: function (el) {
            // 当入场之前会执行 v-enter
            el.style = 'padding-left:100px'
        },
        enter: function (el, done) {
            // 当进行的过程中每执行 v-enter-active

            // 为了能让代码正常进行，在设置了结束状态后必须调用一下这个元素的
            // offsetHeight / offsetWeight  只是为了让动画执行
            el.offsetHeight
            // 结束的状态最后啊写在enter中
            el.style = 'padding-left:0px'
            // 执行done继续向下执行
            done()
        },
        afterEnter: function (el) {
            // 当执行完毕以后会执行

            this.isShow = true
        },

        // 控制快捷按钮的显示/隐藏
        fixedActiveBtn() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            scrollTop >= 545 //滚动的高度
                ? (this.fixed = true)
                : (this.fixed = false)
            // console.log('滚动高度',scrollTop,this.fixed)
        },

        handleScroll() {
            let scrolltop =
                document.documentElement.scrollTop || document.body.scrollTop
            scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
        },
        //  回到顶部
        toTop() {
            // this.navgatorIndex = 0
            let top = document.documentElement.scrollTop || document.body.scrollTop

            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50
                if (top <= 0) {
                    clearInterval(timeTop)
                }
            }, 10)
        },
        mouseOver(id, index) {
            this.isShow = id
        },
    },
}
</script>

<style lang="scss" scoped>
#serviceSystem {
    ::v-deep.el-dialog {
        .el-icon-success:before {
            font-size: 65px;
            color: #11c511;
        }

        .title {
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: center;

            span {
                position: relative;
                top: -12px;
                padding-left: 13px;
            }
        }

        .con {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            text-align: center;
            padding-top: 18px;
            padding-bottom: 27px;
        }

        .el-dialog__body {
            padding-bottom: 0;
        }

        .el-dialog__footer {
            padding: 0;
            padding-bottom: 50px;

            .el-button {
                width: 148px;
                height: 42px;
                background: #ff8d28;
                border-color: #ff8d28;
            }
        }
    }

    .head {
        width: 100%;
        height: 100%;
        background: white;

        .logo {
            width: 1200px;
            height: 88px;
            background: white;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 22px;
            padding-left: 22px;

            .imgBox {
                overflow: hidden;

                img {
                    width: 300px;
                    height: 58px;
                    transition: all 0.6s;
                }
            }

            .imgBox img:hover {
                transform: scale(1.1);
            }

            #service {
                width: 638px;
                display: flex;
                justify-content: space-evenly;

                .nav {
                    padding-right: 5px;
                    padding-left: 5px;
                    height: 88px;
                    line-height: 88px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #474747;
                    cursor: pointer;
                }
            }
        }
    }

    .gree {
        background: #03825a;
        color: white !important;
    }

    .shortcut {
        position: fixed;
        z-index: 99999;
        right: 80px;
        top: 400px;
        width: 68px;
        background: #494949;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;

        .consultBox {
            width: 68px;
            height: 68px;
            text-align: center;
            border: 1px solid rgba(199, 199, 199, 0.2);
            cursor: pointer;

            span {
                display: inline-block;
                width: 33px;
                height: 39px;
                margin: 0 auto;
                margin-top: 16px;
                position: relative;

                .consultPhone {
                    height: 68px;
                    background: #03825a;
                    color: #ffffff;
                    padding: 0px 30px;
                    position: absolute;
                    left: -218px;
                    top: -17px;
                }

                .icon_img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }

        div:hover {
            background: #03825a;
            color: #ffffff;
        }

        .topText {
            img {
                width: 19px;
                height: 10px;
                padding: 15px 24px 10px 25px;
            }

            p {
                margin-top: -5px;
                margin-bottom: 15px;
            }
        }

        .tran {
            transition: all 0.2s;
        }
    }

    .footerAppoint {
        width: 100%;
        height: 150px;
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        bottom: 0;
        z-index: 2000;
        transition: all 0.2s;

        .appoint-main {
            position: relative;
            width: 1200px;
            height: 100%;
            margin: 0 auto;

            .appoint-icon {
                position: absolute;
                top: 0;
                right: 0;

                img {
                    width: 38px;
                    height: 38px;
                }
            }

            .appoint-body {
                .title {
                    font-size: 24px;
                    color: #f2f5f5;
                    padding-top: 25px;
                    padding-bottom: 22px;
                    text-align: center;

                    .title_text {
                        font-size: 30px;
                        color: #00c084;
                    }

                    .left {
                        margin-right: 22px;
                    }

                    .right {
                        margin-left: 10px;
                    }
                }

                ::v-deep.el-form {
                    display: flex;
                    justify-content: center;
                    padding-right: 107px;
                    padding-left: 107px;

                    .el-input {
                        width: auto;
                        margin-right: 10px;
                    }

                    .note {
                        position: relative;
                        margin-right: 10px;

                        .codeInput {
                            height: 40px;
                            // border-radius: 4px;
                            border: 1px solid #dcdfe6;
                            box-sizing: border-box;
                            padding-left: 10px;
                        }

                        .code {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            margin-top: -15px;

                            .sendMsg {
                                width: 78px;
                                height: 30px;
                                background: #323643;
                                // border-radius: 4px;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                color: #ffffff;
                            }
                        }
                    }

                    .el-button {
                        background: #00c084;
                        border-color: #00c084;
                        border-radius: 0px;
                    }

                    .address {
                        .el-form-item__content {
                            .el-cascader {
                                .el-input {
                                    width: 200px;
                                }
                            }
                        }
                    }

                    .el-input__inner {
                        border-radius: 0px;
                    }
                }
            }
        }
    }
}
</style>